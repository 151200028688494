export default () => {
  const sentinel = document.querySelector('#sentinel')
  const headerClass = document.querySelector('[data-is-accommodation-nav]')
  const windowHeight = window.innerHeight

  if (headerClass === null) return

  const intersectionObserverScroll = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      headerClass.classList.toggle('nav-accommodation--scroll', !entry.isIntersecting)
    })
  }, {
    rootMargin: windowHeight + 200 + 'px'
  })

  const intersectionObserverActive = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      headerClass.classList.toggle('nav-accommodation--active', !entry.isIntersecting)
    })
  }, {
    rootMargin: windowHeight + 400 + 'px'
  })

  intersectionObserverScroll.observe(sentinel)
  intersectionObserverActive.observe(sentinel)
}
