<template lang="pug">
div
  v-runtime-template(v-if="accommodationsList" :template="accommodationsList")
  div(v-else)
    slot
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'

export default {
  components: {
    VRuntimeTemplate
  },

  computed: {
    accommodationsList () {
      return this.$store.getters.getAccommodationsList
    }
  }
}
</script>
