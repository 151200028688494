export default () => {
  const sectorSections = document.querySelectorAll('[data-is-sector-section]')
  const sectorNavItems = document.querySelectorAll('[data-is-sector-nav-item]')

  if (sectorSections === null) return

  const intersectionObserverScroll = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        sectorNavItems.forEach(navItem => navItem.classList.remove('active'))

        document.querySelector(`[data-target='${entry.target.id}']`).classList.add('active')
      }
    })
  }, {
    rootMargin: '-300px'
  })

  sectorSections.forEach(sector => intersectionObserverScroll.observe(sector))
}
