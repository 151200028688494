export default () => {
  const sentinel = document.querySelector('#sentinel')
  const headerClass = document.querySelector('.main-header--transparent')

  if (headerClass === null) return

  const intersectionObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      headerClass.classList.toggle('header--scroll', !entry.isIntersecting)
    })
  }, {
    rootMargin: "1px"
  })

  intersectionObserver.observe(sentinel)
}
