import Vue from 'vue'
import store from '@store'
import axios from 'axios'
import FlashMessage from "@utils/flash-messages"
import VueScrollTo from 'vue-scrollto'
import Transitions from 'vue2-transitions'
import VueMq from 'vue-mq'
import PrettyCheckbox from 'pretty-checkbox-vue'
import VModal from 'vue-js-modal'

// Components
import ActiveItem from '@components/ActiveItem'
import AccommodationList from '@components/AccommodationList'
import Agile from '@components/Agile'

// Directive
import vClickOutside from 'v-click-outside'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

// Components
Vue.component('CustomMap', () => import('@components/CustomMap'))
Vue.component('CustomMapMarker', () => import('@components/CustomMapMarker'))
Vue.component('ActiveItem', ActiveItem)
Vue.component('AccommodationList', AccommodationList)
Vue.component('ContactForm', () => import(/* webpackPrefetch: true */ '@components/ContactForm'))
Vue.component('Agile', Agile)

Vue.component('SearchbarWrapper', () => import(/* webpackChunkName: "searchbar" */ '@components/Searchbar/SearchbarWrapper'))
Vue.component('SearchbarToggle', () => import(/* webpackPrefetch: true */ /* webpackChunkName: "searchbar" */ '@components/Searchbar/SearchbarToggle'))
Vue.component('Multiselect', () => import('vue-multiselect'))
Vue.component('FormMultiselect', () => import(/* webpackPrefetch: true */ '@components/FormMultiselect'))
Vue.component('ContactScrollButton', () => import(/* webpackPrefetch: true */ '@components/ContactScrollButton'))
Vue.component('AccommodationAlert', () => import(/* webpackPrefetch: true */ '@components/AccommodationAlert'))
Vue.component('EstimateFormTypeSelect', () => import(/* webpackPrefetch: true */ /* webpackChunkName: "estimate" */ '@components/Estimate/AccommodationTypeSelect'))
Vue.component('EstimateFormCitySelect', () => import(/* webpackPrefetch: true */ /* webpackChunkName: "estimate" */ '@components/Estimate/CitySelect'))

// Plugins
Vue.use(Transitions)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: Infinity
  }
})
Vue.use(PrettyCheckbox)
Vue.use(vClickOutside)
Vue.use(VModal)
Vue.use(VueScrollTo)

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  axios.interceptors.response.use((response) => {
    return response
  }, function (error) {
    new FlashMessage("Une erreur s'est produite", "danger")
    return Promise.reject(error)
  })

  new Vue({
    el: '#vue-app',
    store,
    mounted () {
      this.$modal.show('modal-social')
    }
  })
})

Vue.prototype.$event = new Vue()
