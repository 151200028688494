import axios from 'axios'

const state = {
  endpoint: '/accommodations/search',
  params: {},
  maxBudget: null,
  maxSurface: null,
  accommodationsList: null,
  alertTags: null,
  childrenReady: {
    bedroomsCheckboxes: false,
    locationSelect: false,
    budgetRange: false,
    contractType: false,
    sectorsSelect: false,
    typeSelect: false,
    surfaceRange: false
  }
}

const mutations = {
  updateParams (state, payload) {
    if (payload.value === '' || payload.value === null) return false

    state.params = {
      ...state.params,
      [payload.key]: payload.value
    }
  },

  updateReady (state, payload) {
    state.childrenReady = {
      ...state.childrenReady,
      [payload]: true
    }
  },

  setMaxBudget (state, payload) {
    state.maxBudget = payload
  },

  setAccommodationsList (state, payload) {
    state.accommodationsList = payload
  },

  setAlertTags (state, payload) {
    state.alertTags = payload
  },

  setMaxSurface (state, payload) {
    state.maxSurface = payload
  },

  deleteParams (state, payload) {
    delete state.params[payload]
  }
}

const getters = {
  getParams (state) {
    return state.params
  },

  getMaxBudget (state) {
    return state.maxBudget
  },

  getAccommodationsList (state) {
    return state.accommodationsList
  },

  getAlertTags (state) {
    return state.alertTags
  },

  getMaxSurface (state) {
    return state.maxSurface
  }
}

const actions = {
  async searchAccommodations ({ commit }) {
    if (Object.values(state.childrenReady).includes(false)) {
      return Promise.reject(new Error('Not ready'))
    }

    return axios.get(state.endpoint, {
      params: state.params
    })
      .then(({ data }) => {
        const countResult = document.querySelector('#count-result')

        history.pushState(null, null, data.location)

        commit('setAccommodationsList', data.content)
        commit('setAlertTags', data.alertTags)

        countResult.innerHTML = data.countResult

        return data
      })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
