/* eslint no-console:0 */
/* eslint-disable */

// Polyfill =====================================================================
import "intersection-observer"

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "lightgallery.js"
import "lg-thumbnail.js"
import "readmore-js"
import SmoothScroll from 'smooth-scroll'

import "@appPages/vue"

import "stylesheets/application.sass"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import HeaderClass from "@utils/header-class"
import SectorNav from "@utils/sector-nav"
import HeaderAccommodation from "@utils/header-accommodation"
import ImageLoad from "@utils/image-load"
import AnimateScroll from "@utils/animate-scroll"

if (process.env.NODE_ENV === "development") {
  import("stylesheets/accessibility.sass")
}

// Fonts ===============================================================
import "typeface-unna"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

window.recaptchaCallback = () => {
  document.getElementById('new_contact').submit()
}

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new HeaderClass()
  new HeaderAccommodation()
  new ImageLoad()
  new AnimateScroll()
  new SectorNav()

  new lightGallery(document.querySelector('#lightgallery'), {
    thumbnail: true,
    mode: 'lg-fade',
    cssEasing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    speed: 800,
    download: false
  })

  new SmoothScroll('a[href*="#"]', {
    speed: 400,
    offset: 120,
    updateURL: false
  })

  $('[data-readmore]').each(function () {
    let customHeight = parseInt(this.getAttribute('data-readmore')) ? parseInt(this.getAttribute('data-readmore')) : 310
    $(this).readmore({
      collapsedHeight: customHeight,
      moreLink: '<a href="#">Lire la suite</a>',
      lessLink: '<a href="#">Réduire</a>',
    })
  })
})
