<template lang="pug">
  vue-agile(v-on="$listeners" v-bind="$attrs" :options="agileOptions")
    slot
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  components: {
    VueAgile
  },

  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    defaultOptions () {
      return {
        centerMode: true,
        navButtons: false,
        dots: true,
        autoplaySpeed: 5000,
        speed: 800,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              autoplay: false,
              dots: false
            }
          }
        ]
      }
    },

    agileOptions () {
      return {
        ...this.defaultOptions,
        ...this.options
      }
    }
  }
}
</script>
